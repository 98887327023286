import * as Redux from "redux";
import { createStore, applyMiddleware, compose } from "redux";
import promise from "redux-promise";
import logger from "redux-logger";
import { throttle } from "lodash";
import { generateState } from "./state/generateState";
import { loadState, saveState } from "./localStorage";
import reducer from "../reducers";
let thunk = require("redux-thunk").default;
let configureStore: {(): Redux.Store<any>} = undefined;

declare let window: any;
declare let module: any;

configureStore = () => {

  const persistedState = loadState();

  let store = createStore(
    reducer,
    persistedState ? persistedState : generateState(),
    compose(
      process.env.NODE_ENV === "development" ?
        applyMiddleware(thunk, promise, logger) :
        applyMiddleware(thunk, promise),
      typeof window !== "undefined" && window.devToolsExtension ? window.devToolsExtension() : f => f
    )
  );

  // Save state to local storage
  store.subscribe(throttle(() => {
    saveState(store.getState());
  }, 1000));

  if (module.hot && process.env.NODE_ENV === "development") {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      const nextRootReducer = require("../reducers").default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
