import { merge } from "lodash";

export const GET_ANSWER_STATS = "GET_ANSWER_STATS";
export const MAKE_CHOICE = "MAKE_CHOICE";
export const SUBMIT_ANSWERS = "SUBMIT_ANSWERS";

const axios = require("axios").default;

export let answer = (questionId: Number, answer) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: MAKE_CHOICE,
      questionId,
      answer
    }));
  };
};

export let submitAnswers = (questions: [any], answers: [Number]) => {
  return async (dispatch, getState) => {

    const requests = questions.map((question, i) => {
      return axios.post(`/answer/create?questionId=${question.id}&answer=${answers[i]},`);
    });

    await axios.all(requests).then(() => {
      dispatch(merge({}, {
        type: SUBMIT_ANSWERS,
        success: true
      }));
    }).catch((err) => {
      console.log("error: ", err);
    });
  };
};

export let getAnswerStats = (questionId: Number) => {
  return async (dispatch, getState) => {
    const data = await axios.get(`/answer/stats`, { params: { questionId: questionId }}).then((response) => {
      return response.data;
    }).catch((error) => {
      console.log("error:", error);
    });

    const { answerTotals, total } = data;

    await dispatch(merge({}, {
      type: GET_ANSWER_STATS,
      questionId,
      answerTotals,
      total
    }));

  };
};
