import * as React from "react";
import { connect } from "react-redux";

class Directions extends React.Component<any, any> {

  render() {

    return (
      <div>
        <h2>Conflict Management Styles self-test:</h2>
        <p>Please complete the following self-assessment to determine your tendencies when managing conflict.</p>
        <p>For each item, select a number to indicate how often you rely on that tactic.</p>


        <div className="callout">
          Reference for the Conflict Management Styles self-test:
          <br/>
          M. A. Rahim, June 1983, <i>A Measure of Styles of Handling Interpersonal Conflict, Academy of Management Journal</i>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(Directions);
