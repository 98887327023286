import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import * as Chart from "chart.js";

const axios = require("axios").default;

export class Result extends React.Component<any, any> {

  barChartColors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"];
  barChartLabels = ["Collaborating", "Accommodating", "Competing", "Avoiding", "Compromising"];

  constructor(props) {
    super(props);
  }

  // Creates a chart.js chart using provided results array
  createChart(results: Array<String>, elementID: string) {

    const canvas = document.getElementById(elementID) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");

    // Add results to labels
    const labelsWithResults = this.barChartLabels.map((label, i) => {
      let roundedResult = Number(results[i]).toFixed(1);
      return [label, roundedResult];
    });

    new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labelsWithResults,
          datasets: [{
            label: "Score",
            data: results,
            backgroundColor: this.barChartColors,
            borderColor: this.barChartColors,
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMax: 5
              }
            }]
          },
          legend: false,

        }
    });
  }

  async componentDidMount() {
    const { answers } = this.props;
    let userData;

    const totalData = await axios.get(`/question/stats/total`)
      .then((response) => {
        return response.data.totals;
      })
      .catch((err) => console.log("error:", err));

    this.createChart(totalData, "total-results");

    if (window.location.hash !== '#/admin') {
      userData = await axios.get(`/question/stats/user?answers=[${answers.answers}]`)
        .then((response) => {
          return response.data.totals;
        })
        .catch((err) => console.log("error:", err));
      this.createChart(userData, "user-results");
    }
    
  }

  render() {


    return (
      <div className={"result-container"}>

        {
          window.location.hash !== '#/admin' ?
            <div>
              <Link to={"/"}>
                <button className="btn"><i className="fa fa-arrow-left"/> Back to Questions</button>
              </Link>
              <h2 className={"result-text"}>Your Results:</h2>
              <canvas id="user-results" style={{maxWidth: "700px"}}></canvas>
              <br/><br/>
            </div>
            :
            null
        }

        <h2  className={"result-text"}>Average Student Results:</h2>
        <canvas id="total-results" style={{maxWidth: "700px"}}></canvas>
        <br/><br/>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { currentQuestion, answers, questions } = state;
  return {
    currentQuestion,
    answers,
    questions
  };
};

export default withRouter(connect(mapStateToProps)(Result));
