export let generateState = () => {
  return {
    questions: [],
    currentQuestion: -1,
    answers: {
      answers: [],
      answerTotals: null,
      submitted: false
    }
  };
};

export default generateState;
