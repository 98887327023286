import * as React from "react";
import { connect } from "react-redux";
import Directions from "./directions";
import Question from "./question";
import Navigation from  "../components/header/navigation";

class Home extends React.Component<any, any> {

  render() {
    const {currentQuestion, totalQuestions} = this.props;

    return (
      <div>
        <div 
          className={`question-count ${currentQuestion < 0 ? "hidden" : ""}`}>
          Question {currentQuestion + 1}/{totalQuestions}
        </div>
        {currentQuestion === -1 ? <Directions/> : <Question/>}
        {window.location.pathname === '/admin' ? null : <Navigation/>}

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let { 
    currentQuestion, 
    questions: { totalQuestions } 
  } = state;
  return { currentQuestion, totalQuestions };
};

export default connect(mapStateToProps)(Home);
