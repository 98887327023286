import * as React from "react";

export class Header extends React.Component<any, any> {
  render() {
    return (
      <div id="header">
        <div className="title">
          <h1>Conflict Self Survey</h1>
        </div>
      </div>
    );
  }
}

export default Header;
