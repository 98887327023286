import { MAKE_CHOICE } from "../actions/userChoices";
import {GET_ANSWER_STATS, SUBMIT_ANSWERS} from "../actions/answer";
import {RESET_STATE} from "../actions/questions";

const initialState = {
  answers: [],
  total: null,
  answerTotals: null,
  submitted: false
};

export let reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANSWER_STATS: {
      let nextState = Object.assign({}, state, {total: action.total, answerTotals: action.answerTotals });
      return nextState;
    }
    case SUBMIT_ANSWERS: {
      let nextState = Object.assign({}, state, {submitted: true});
      return nextState;
    }
    case MAKE_CHOICE: {
      let nextState = Object.assign({}, state);
      nextState.answers[action.questionId] = action.answer;
      return nextState;
    }
    case RESET_STATE: {
      let nextState = Object.assign({}, initialState);
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
