import { merge } from "lodash";

export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_DISPLAY_RESULT = "SET_DISPLAY_RESULT";
export const RESET_STATE = "RESET_STATE";

export let setDisplayResult = (questionId: Number) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: SET_DISPLAY_RESULT,
      showResults: true
    }));
  };
};

export let setQuestions = (questions, currentQuestions = -1) => {
  return async (dispatch, getState) => {
    const now = new Date().getTime().toString();
    await dispatch(merge({}, {
      type: SET_QUESTIONS,
      questions,
      currentQuestions,
      showResults: false,
      loadedAt: now
    }));
  };
};

export let resetState = () => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: RESET_STATE
    }));
  };
};
