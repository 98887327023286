import { merge } from "lodash";

export const MAKE_CHOICE = "MAKE_CHOICE";

export let answer = (questionId: Number, answer) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: MAKE_CHOICE,
      questionId,
      answer
    }));
  };
};

