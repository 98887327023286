import * as React from "react";
import { get, post } from "superagent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class QuizEditor extends React.Component<any, any> {
  
  constructor(props) {
    super(props)

    this.state = {
      questions: [],
      newQuestion: "",
      error: null,
      currentQuestion: {text: "", id: 0},
      dialog: {open: false, text: "", name: ""},
      saved: false,
      undone: false
    }

    this.addQuestion = this.addQuestion.bind(this);
    this.updateQuestionText = this.updateQuestionText.bind(this);
    this.updateQuestionReverse = this.updateQuestionReverse.bind(this);
    this.updateQuestionCategory = this.updateQuestionCategory.bind(this);
    this.saveQuestionText = this.saveQuestionText.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  // Gets questions from DB
  async getQuestions() {
    await get(`/question/all`)
    .withCredentials()
    .then(res => {
      this.setState({questions: res.body});
    })
    .catch(err => {
      this.setState({isAuthenticated: false, error: err});
    });
  };

  // Adds a new question to the questions array
  addQuestion() {
    const { questions } = this.state;
    const newQuestion = {
      text: "New Question",
      deleted: false,
      reversed: false,
      category: "Collaborating"
    };
    const nextQuestions = [...questions, newQuestion];
    const nextIndex = nextQuestions.length - 1;
    this.setState({
      questions: nextQuestions, 
      currentQuestion: {text: nextQuestions[nextIndex].text, index: nextIndex}
    });
  }

  // Updates the question text
  updateQuestionText(e) {
    const { currentQuestion } = this.state;
    let updatedQuestion = Object.assign({}, currentQuestion);
    updatedQuestion.text = e.target.value
    this.setState({currentQuestion: updatedQuestion});
  }

  // Updates whether or not the question has "reverse" scoring
  updateQuestionReverse(e, i) {
    const { questions } = this.state;
    let newQuestions = [...questions];
    newQuestions[i].reversed = e.target.checked;
    this.setState({questions: newQuestions});
  }

  // Updates question grouping
  updateQuestionCategory(e, i) {
    const { questions } = this.state;
    // Get HTML list of options
    const options = Array.prototype.slice.call(e.target.options);
    let category = [];
    // Loop through each, keep the ones that are selected
    options.forEach(o => {
      if (o.selected) { 
        category.push(o.value);
      }
    });
    
    let newQuestions = [...questions];
    newQuestions[i].category = category.join(",");
    this.setState({questions: newQuestions});
  }

  // Saves the currentQuestion into the list
  saveQuestionText() {
    const { questions, currentQuestion } = this.state;
    let newQuestions = [...questions];
    newQuestions[currentQuestion.index].text = currentQuestion.text;
    this.setState({questions: newQuestions, currentQuestion: {text: "", index: null}});
  }

  // Switches a question from "deleted: false" to "deleted: true"
  handleDelete(index) {
    const { questions } = this.state;
    let newQuestions = [...questions];
    newQuestions[index].deleted = true;
    this.setState({questions: newQuestions});
  }

  // Sends question array to the server
  async handleSave() {
    await post(`/question/publish`)
    .query({questions: JSON.stringify(this.state.questions)})
    .withCredentials()
    .then(res => {
      // Reset results
      post(`/answer/reset`)
        .withCredentials()
        .then(res => {
          this.setState({
            saved: true,
            dialog: {open: false, text: ""}
          });
        })
        .catch(err => {
          this.setState({proceedReset: false, error: "There was an error resetting results."});
        });
    })
    .catch(err => {
      this.setState({isAuthenticated: false, error: err});
    });
  }

  // Requests questions from database again to start from scratch/remove updates.
  async handleUndo() {
    this.getQuestions();
    if (!this.state.error) {
      this.setState({dialog: {open: false, text: ""}, undone: true});
    }
  }

  openDialog(name) {
    let text;
    if (name === "save") {
      text = "Saving will reset student answers. Are you sure you wish to continue?";
    }
    if (name === "undo") {
      text = "Are you sure you want to reset your changes to the questions?";
    }
    this.setState({dialog: {open: true, text, name}});
  }

  closeDialog() {
    this.setState({dialog: {open: false, text: ""}});
  }

  componentDidMount() {
    this.getQuestions();
  }

  componentDidUpdate(prevProps, prevState) {
    // Remove saved message after 2 seconds
    if (!prevState.saved && this.state.saved) {
      setTimeout(() => {
        this.setState({saved: false});
        // Reload page to show graph changes
        window.location.reload();
      }, 2000)
    } 
    // Remove undone message after 2 seconds
    if (!prevState.undone && this.state.undone) {
      setTimeout(() => {
        this.setState({undone: false})
      }, 2000)
    } 
  }

  render() {
    const { 
      questions, 
      currentQuestion, 
      dialog,
      saved,
      undone
    } = this.state;

    return (
      <div>
        <ul className="question-editor">
          {questions.length > 0 ? 
            questions.map((question, i) => {
              // Only show non-deleted questions
              if (!question.deleted) {
                // Show question as input if editing
                if (currentQuestion.index === i) {
                  return (
                    <li key={i}>
                      <input 
                        value={currentQuestion.text} 
                        key={i}
                        onChange={e => this.updateQuestionText(e)}
                      />
                      <div>
                        <button onClick={this.saveQuestionText}>Save</button>
                        <button 
                          onClick={() => this.setState({currentQuestion: {
                            text: "",
                            index: null
                          }})}>
                          Cancel
                          </button>
                      </div>
                    </li>
                    
                  );
                }
                else {
                  return (
                    <li key={i}>
                      <div className="question-text">{question.text}</div>
                      <div className="options">
                        <button onClick={() => this.setState({
                          currentQuestion: {text: question.text, index: i}
                          })}>Edit</button> 
                        <button onClick={() => this.handleDelete(i)}>Remove</button>
                        <div className="reverse-selection">
                          Reversed
                          <input 
                            type="checkbox"
                            defaultChecked={question.reversed ? true : false}
                            onClick={(e) => this.updateQuestionReverse(e, i)}
                          />
                        </div>
                        <select 
                          name="category" 
                          id="category" 
                          multiple 
                          onChange={(e) => this.updateQuestionCategory(e, i)} 
                          defaultValue={question.category.split(",")}>
                          <option value="Collaborating">Collaborating</option>
                          <option value="Accommodating">Accommodating</option>
                          <option value="Competing">Competing</option>
                          <option value="Avoiding">Avoiding</option>
                          <option value="Compromising">Compromising</option>
                        </select>
                      </div>
                    </li>
                  );
                }
              }
            }) : null
          }
        </ul>
        <button onClick={this.addQuestion}>Add Question +</button>

        <p>
          <span className={`success-message ${saved ? "" : "hidden"}`}>
            Questions successfully saved. Reloading page...
          </span> 
          <span className={`success-message ${undone ? "" : "hidden"}`}>
            Your updates have been undone.
          </span> 
        </p>

        {dialog.open ? 
          <div className="dialog">
            <span className="warning-text">{dialog.text}</span>
            <button 
              className="green" 
              onClick={() => dialog.name === "save" ? this.handleSave() : this.handleUndo()}>
              Continue
            </button>
            <button onClick={() => this.closeDialog()}>Cancel</button>
          </div>
        : null}

        <div className={`${dialog.open || saved || undone ? "hidden" : ""}`}>
          <button 
            className="save" 
            onClick={() => this.openDialog("save")}>Save</button>
          <button className="btn danger" onClick={() => this.openDialog("undo")}>Undo Edits</button>
        </div>

        
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};


export default withRouter(connect(mapStateToProps)(QuizEditor));
