import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { setDisplayResult } from "../actions/questions";
import { getAnswerStats } from "../actions/answer";

export class Button extends React.Component<any, any> {

  state = {
    showingMessage: false,
    loading: false,
    error: false,
    choiceMade: false
  };

  constructor(props) {
    super(props);
    this.getResults = this.getResults.bind(this);
    this.handleClickWithNoChoice = this.handleClickWithNoChoice.bind(this);
  }

  componentDidMount(): void {
    const { answers, currentQuestion } = this.props;

    if (answers.answers[currentQuestion] !== null) {
      this.setState({choiceMade: true});
    }
  }

  componentDidUpdate(prevProps, prevState) {

    const { answers, currentQuestion } = this.props;
    const { choiceMade } = this.state;

    // If an answer has been made and the error message is showing,
    // Remove the error message
    if (!prevProps.choiceMade && this.props.choiceMade && this.state.showingMessage) {
      this.setState({showingMessage : false});
    }

    if (answers.answers[currentQuestion] !== null && !choiceMade) {
      this.setState({choiceMade: true});
    }
  };

  // Send answer to database and return total answers
  async getResults() {
    const { dispatch, currentQuestion, questions } = this.props;
    await dispatch(getAnswerStats(questions.questions[currentQuestion].id));
    await dispatch(setDisplayResult(currentQuestion));
    this.setState({loading: false})
  }

  handleClickWithNoChoice(e) {
    e.preventDefault();
    this.setState({showingMessage: true})
  }

  render() {
    const { answers } = this.props;

    return (
      <div>

        <Link to={"/results"} style={{display: `${answers.submitted ? "initial" : "none"}`}}>
          <button className="green" style={{marginTop: `${answers.submitted ? "20px" : "0px"}`}}>
            Show Results
          </button> 
        </Link>

        {this.state.showingMessage ?
          <div className="select-answer-message">Please select an answer.</div>
        : null}

        {this.state.loading ? 
          <div className="loading">
            <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            <span className="sr-only">Loading...</span>
          </div> : null}

      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  const { questions, currentQuestion, answers } = state;
  return {
    questions, currentQuestion, answers
  };
};

export default connect(mapStateToProps)(Button);
