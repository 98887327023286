import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { answer } from "../actions/userChoices";
import { getAnswerStats } from "../actions/answer";

import Button from "./button";
import Radio from "./radio";
import Result from "./result";

export class Question extends React.Component<any, any> {

  state = {
    questionText: null,
    questionId: null,
    currentChoice: null
  };

  oneText: string = "(1) disagree strongly";
  twoText: string = "(2) disagree";
  threeText: string = "(3) neutral";
  fourText: string = "(4) agree";
  fiveText: string = "(5) agree strongly";

  constructor(props) {
    super(props);
    this.onAnswer = this.onAnswer.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.currentQuestion !== prevProps.currentQuestion) {
      const { currentQuestion, questions, answers, dispatch}  = this.props;
      const currentChoice = answers.answers[currentQuestion] ? answers.answers[currentQuestion] : null;

      this.setState({
        questionId: currentQuestion,
        questionText: questions.questions[currentQuestion].text,
        currentChoice: currentChoice
      });

      if (this.props.questions.showResults[currentQuestion]) {
        dispatch(getAnswerStats(questions.questions[currentQuestion].id));
      }
    }

  }

  componentDidMount(): void {
    const { questions, currentQuestion, answers } = this.props;
    const currentChoice = answers.answers[currentQuestion] ? answers.answers[currentQuestion] : null;
    this.setState({
      questionId: currentQuestion,
      questionText: questions.questions[currentQuestion].text,
      currentChoice: currentChoice
    });

  }

  onAnswer(number): void {
    const { dispatch, answers } = this.props;
    const { questionId } = this.state;
    if (answers.submitted) {
      return;
    }
    this.setState({currentChoice: number});
    dispatch(answer(questionId, number));
  }

  render() {
    const { currentQuestion, questions, answers } = this.props;
    const { questionText, currentChoice } = this.state;

    return (
      <div>
        <h2>{questionText ? questionText : "no data"}</h2>


        <div className="multiple-choice ">
          <div key="1" className="radio disabled"  onClick={() => this.onAnswer(1)}>
            <Radio submitted={answers.submitted} selected={currentChoice === 1}/>
            <div className="text">{this.oneText}</div>
          </div>
          <div key="2" className="radio disabled" onClick={() => this.onAnswer(2)}>
            <Radio submitted={answers.submitted} selected={currentChoice === 2}/>
            <div className="text">{this.twoText}</div>
          </div>
          <div key="3" className="radio" onClick={() => this.onAnswer(3)}>
            <Radio submitted={answers.submitted} selected={currentChoice === 3}/>
            <div className="text">{this.threeText}</div>
          </div>
          <div key="4" className="radio" onClick={() => this.onAnswer(4)}>
            <Radio submitted={answers.submitted} selected={currentChoice === 4}/>
            <div className="text">{this.fourText}</div>
          </div>
          <div key="5" className="radio" onClick={() => this.onAnswer(5)}>
            <Radio submitted={answers.submitted} selected={currentChoice === 5}/>
            <div className="text">{this.fiveText}</div>
          </div>
        </div>

        <br/>
        {(currentQuestion === questions.questions.length - 1) && answers.submitted ?
          <div>
            <div className="compare-answer">
              <Button questionId={currentQuestion} choiceMade={answers.answers[currentQuestion]}/>
            </div>
          </div>
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questions, currentQuestion, questionText, questionId, currentChoice, answers } = state;
  return {
    questions,
    currentQuestion,
    questionText,
    questionId,
    currentChoice,
    answers
  };
};


export default withRouter(connect(mapStateToProps)(Question));
