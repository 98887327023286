import * as React from "react";
import { get, post } from "superagent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import QuizEditor from "./quizEditor";
import Result from "./result";

export class Admin extends React.Component<any, any> {
  
  state = {
    isAuthenticated: false,
    proceedReset: false,
    resetSuccess: false,
    error: null
  }

  // Checks if admin is logged in
  async isAuthenticated() {
    await get(`/login`)
    .withCredentials()
    .then(res => {
      this.setState({isAuthenticated: true});
    })
    .catch(err => {
      this.setState({isAuthenticated: false, error: err});
    });
  };

  // Sends a post request to /answer/reset to delete student results
  async resetResults() {
    await post(`/answer/reset`)
    .withCredentials()
    .then(res => {
      this.setState({proceedReset: false, resetSuccess: true});
    })
    .catch(err => {
      this.setState({proceedReset: false, error: "There was an error resetting results."});
    });
  }

  componentDidMount() {
    this.isAuthenticated();
  }

  componentDidUpdate(prevProps, prevState) {
    // After 2 seconds, hide the reset success message
    if (!prevState.resetSuccess && this.state.resetSuccess) {
      setTimeout(() => {
        this.setState({resetSuccess: false});
        window.location.reload();
      }, 2000)
    }
  }

  render() {
    const { isAuthenticated, error, proceedReset, resetSuccess } = this.state;
    return (
      <div id="admin">
        {isAuthenticated ?
          <div>
            <Result resetResults={resetSuccess}/>
            <button 
              className={`btn danger ${proceedReset || resetSuccess ? "hidden" : "null"}`} 
              onClick={() => this.setState({proceedReset: true})}>
              Reset Student Results
            </button>
            
            <div className={`dialog ${proceedReset && !resetSuccess ? "" : "hidden"}`}>
              <span className="warning-text">
                Are you sure you want to reset all student results?
              </span>
              <button 
                className="green" 
                onClick={() => this.resetResults()}>
                Continue
              </button>
              <button onClick={() => this.setState({proceedReset: false})}>
                Cancel
              </button>
            </div>
            
            <p>
              <span className={`success-message ${resetSuccess ? "" : "hidden"}`}>
                Student results successfully reset. Reloading page...
              </span>
            </p> 
            <br/><br/>
            <QuizEditor/>
          </div>

          : null}
        {error ? 
          <div className="alert alert-danger" role="alert">
            There was an error logging in.
          </div> 
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};


export default withRouter(connect(mapStateToProps)(Admin));
