import { merge } from "lodash";

export const GO_TO_QUESTION = "GO_TO_QUESTION";

export let goToQuestion = (questionId: Number) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: GO_TO_QUESTION,
      questionId
    }));
  };
};
