import { GO_TO_QUESTION } from "../actions/currentQuestion";
import {RESET_STATE} from "../actions/questions";


const initialState = {};

export let reducer = (state = initialState, action) => {
  switch (action.type) {
    case GO_TO_QUESTION: {
      return action.questionId;
    }
    case RESET_STATE: {
      return -1;
    }
    default:
      return state;
  }
};

export default reducer;
