import * as React from "react";

export class Radio extends React.Component<any, any> {
    render() {
        const { selected, submitted } = this.props;

        return (
            <div className={`radio-outer ${submitted ? "disabled" : ""}`}>
                {selected ? <div className="radio-inner"/> : null}
            </div>
        );
    }
}
  
export default Radio;
