import * as React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, HashRouter } from "react-router-dom";
import { goToQuestion } from "./actions/currentQuestion";
import { resetState, setQuestions } from "./actions/questions";
import { Header } from "./components/header";

import Result from "./components/result";
import Admin from "./components/admin";
import Home from "./components/home";

const axios = require("axios").default;

export class App extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.checkForQuestionUpdates = this.checkForQuestionUpdates.bind(this);
  }

  async componentDidMount() {
    const { currentQuestion, dispatch } = this.props;
    const updateQuestions = await this.checkForQuestionUpdates();

    if (updateQuestions) {
      await dispatch(resetState());
      await this.getQuestions();
    } else if (currentQuestion !== -1){
      dispatch(goToQuestion(currentQuestion));
    }
  }

  async checkForQuestionUpdates(): Promise<boolean> {
    const { loadedAt } = this.props.questions;

    if (!loadedAt) {
      return true;
    } else {
      return await axios.get(`/question/update?loadedAt=${loadedAt}`)
        .then((response) => {
          return response.data.loadQuestions;
        })
        .catch((err) => console.log("error:", err));
    }

  }

  async getQuestions() {
    await axios.get(`/question/all`).then((response) => {
      this.props.dispatch(setQuestions(response.data));
    }).catch((error) => {
      console.log("error:", error);
    });
  }

  render() {
    return (
      <HashRouter>
        <div className="container" id="page">
          <Header/>
          <div className="content">
            <Route exact path={"/"} component={Home}/>
            <Route path={`/results`} component={Result}/>
            <Route path={`/admin`} component={Admin}/>
          </div>
        </div>
      </HashRouter>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  let { currentQuestion, questions } = state;
  return {
    currentQuestion,
    questions
  };
};

export default connect(mapStateToProps)(App);
