export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("gibbons-self-test");

    // If no saved state in browser, return undefined so
    // the app can create a new default state
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } 
  catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("gibbons-self-test", serializedState);
  }
  catch (err) {
    // Ignore errors
  }
};
